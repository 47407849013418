<template>
  <div class="data-cont">
    <div class="tit">
      <!--    {{dataDeatil.name}}-->
      <Richtxt :dataObj="{val:dataDeatil.name}" :isInline="true" :isDisabled="true"></Richtxt>
    </div>
    <div class="options">
      <div v-for="(item, i) in dataDeatil.options" :key="item.id" >
        <Radio v-if="dataDeatil.type == 1 || dataDeatil.type == 2 || dataDeatil.type == 3" v-model="item.is_correct == 1" disabled> {{String.fromCharCode(i+65)}}
          <span class="options-item">
            <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
          </span>
        </Radio>
        <!--              填空-->
        <Input v-if="dataDeatil.type == 4" class="cont-input" disabled size="large" v-model="item.myAnswer" >
          <span slot="prepend">{{i + 1}}</span>
        </Input>
      </div>
    </div>
    <div style="display: flex;justify-content: flex-start">
      <div class="correct" style="margin-right: 30px;" >
        {{$t('dataset_data_right_key')}}：<span style="font-weight: bold;">{{getRightAnswer()}}</span>
      </div>
      <div class="correct" v-if="stuAnswer.length" style="color:#ed4014">
        你的答案：<span style="color:#ed4014;font-weight: bold;">{{stuAnswer.join(',')}}</span>
      </div>
    </div>

    <div class="analysis" style="display: inline-block;">
      <Richtxt :dataObj="{val:dataDeatil.explain}" :isInline="true" :isDisabled="true"></Richtxt>
      <!--    {{dataDeatil.analysis}}-->
    </div>
  </div>
</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "contVue",
  data(){
    return {
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      questionType:'',
      typesList:[],
      stuAnswer:[],
    }
  },
  components:{
    Richtxt
  },
  props:{
    dataDeatil:{
      type:Object,
      default:()=>{}
    },
    typesObj:{
      type:Object,
      default:()=>{}
    },
    userWrong:{
      type:Object,
      default:()=>{}
    }
  },
  watch:{
    typesObj:{
      handler(newV){
        this.typesList = [];
        for(let name in newV){
          this.typesList.push({
            name:newV[name],
            value:name
          })
        }
      },
      immediate:true
    },
    userWrong:{
      handler(newV){
        if(newV.answer){
          let arr = newV.answer.split(',');
          this.stuAnswer=[];
          let optionName = ['A','B','C','D','E','F','G','H'];

          this.dataDeatil.options.forEach((item,index)=>{
            if(this.dataDeatil.type == 1 || this.dataDeatil.type == 2 || this.dataDeatil.type == 3){
              let curIndex = arr.findIndex((sItem)=>{
                return sItem == item.id;
              });
              if(curIndex != -1){
                this.stuAnswer.push(optionName[index]);
              }
            }else if(this.dataDeatil.type == 4){
              this.$set(item,'myAnswer',arr[index]);
            }
          })

          console.log(this.stuAnswer,'this.stuAnswer')
        }else{
          this.dataDeatil?.options?.forEach((item,index)=>{
            if(this.dataDeatil.type == 4){
              this.$set(item,'myAnswer',item.name);
            }
          })
        }
      },
      immediate:true
    }
  },
  methods:{
    getRightAnswer(){
      var rightanswer = '';
      if(this.dataDeatil.options){
        this.dataDeatil.options.forEach((item, i)=>{
          if(this.dataDeatil.type == 1 || this.dataDeatil.type == 2 || this.dataDeatil.type == 3){
            if (item.is_correct == '1'){
              var codestr = (i + 65);
              rightanswer +=  String.fromCharCode(codestr); //str.charCodeAt()
            }
          }else if(this.dataDeatil.type == 4){
            if(i < this.dataDeatil.options.length - 1){
              rightanswer += item.name + ',';
            }else{
              rightanswer += item.name;
            }
          }

        });
        console.log(rightanswer,'rightanswerrightanswer')
        return rightanswer;
      }

    },
    correction(){
      this.correctionModal = true;
      this.correctionInfo = JSON.parse(JSON.stringify(this.dataDeatil));
      this.questionType = this.typesObj[this.correctionInfo.type];
    },
    saveCurData(curData,data,name){
      data[name] = curData;
    },
  }
}
</script>

<style scoped lang="scss">
.data-cont{
  margin-top: 20px;
  font-size: 14px;

  .tit{
    font-size: 16px;
    display: inline-block;
  }
  .options{
    margin-top: 10px;
    margin-left: 10px;
    >div{
      line-height: 40px;
    }
    .options-item{
      display: inline-block;
    }
  }
  .correct{
    margin-top: 10px;
    margin-bottom: 10px;
    >span{
      color: #2d8cf0;
    }
  }
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;
    }
  }
  .analysis{
    margin-top: 20px;
  }
}
.cont-input{
  margin-bottom: 20px;
}
</style>
