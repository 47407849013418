<template>
  <div class="test-info">
    <div style="padding: 20px 0 0 20px;">
      <MyBreadcrumb></MyBreadcrumb>
    </div>

    <div class="layout-content" >
      <div v-if="!loading">
        <Row>
          <i-col span="2">
            <!--            <img v-if="siteDetail.logo" :src="siteDetail.logo">-->
            <div class="head-img">
              <p>{{dataDeatil.title && dataDeatil.title[0].toUpperCase()}}</p>
              <!--            <img v-if="taskDeatil.project.category" :src="constant.STATIC_URL + taskDeatil.project.category.thumbnail" style="width: 70%;"/>-->
            </div>
          </i-col>
          <i-col span="22">
            <Row>
              <i-col span="24" class="project-detail-info">
                <div class="info-item" >
                  <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">
                    <Richtxt :dataObj="{val:dataDeatil.title}" :isInline="true" :isDisabled="true"></Richtxt>
                  </strong>
                </div>
              </i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">题型 :  {{typesObj[dataDeatil.type]}}</i-col>
              <i-col span="6">难度 :  {{levelsObj[dataDeatil.level] }}</i-col>
              <i-col span="6">创建时间:{{dataDeatil.created_at}}</i-col>
              <i-col span="6">更新时间:{{dataDeatil.updated_at}}</i-col>
            </Row>
          </i-col>
        </Row>
      </div>
      <Spin fix v-else></Spin>
    </div>
    <div class="cont">
      <div class="cont-top">
        <div class="tab">
          <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="cont-item">
        <component :is="curTab.componentName" :typesObj="typesObj" :dataDeatil="dataDeatil" :userWrong="userWrong" @edit="editSuccess"></component>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Cont from './components/data/cont'
import Richtxt from '@/components/production/richtxt.vue';

export default {
  name: "test-info",
  data(){
    return{
      dataDeatil:{},
      dataId:'',
      loading:false,
      typesObj:{},
      levelsObj:{},
      tabList:[
        {
          name:'试题内容',
          id:1,
          componentName:'Cont',
          tabName:'cont'
        }
      ],
      curTab:{
        name:'试题内容',
        id:1,
        componentName:'Cont',
        tabName:'cont'
      },
      listId:'',
      userWrong:{}
    }
  },
  components:{
    Cont,
    Richtxt
  },
  created(){
    this.dataId = this.$route.params.id;
    this.listId = this.$route.params.listId;
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        action:'testDetail',
        data_id:this.dataId,
      };
      if(this.listId){
        params.data_user_wrong_id = this.listId;
      }
      this.loading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        
        this.loading = false;
        this.dataDeatil = res.data;
        this.typesObj = res.types;
        this.levelsObj = res.levels;
        this.userWrong = res.user_wrong
        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.dataDeatil.created_at = util.timeFormatter(
            new Date(+res.data.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.dataDeatil.updated_at = util.timeFormatter(
            new Date(+res.data.updated_at * 1000),
            'yyyy-MM-dd'
        );
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        name: 'my-detail',
        params: {
          id: this.$route.params.id,
          tab: data.tabName,
        }
      });
    },
    editSuccess(){
      this.getDetail();
    }
  }
}
</script>

<style scoped lang="scss">
.test-info{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 100px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
}
</style>
